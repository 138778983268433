import { render, staticRenderFns } from "./ArtPage.vue?vue&type=template&id=119cef0d"
import script from "./ArtPage.vue?vue&type=script&lang=js"
export * from "./ArtPage.vue?vue&type=script&lang=js"
import style0 from "./ArtPage.vue?vue&type=style&index=0&id=119cef0d&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports